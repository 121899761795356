import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { InputLabel, MenuItem, FormControl, Select, Box } from '@mui/material';
import { db } from './firebase';
import Button from '@mui/material/Button';
import { useState } from 'react';
import ErrorPage from './ErrorPage';
import HostLobby from './HostLobby';
import HostGame from './HostGame';
import './HostEntry.css';

const HostEntry = () => {
    const [docId, setDocId] = useState('');
    const [errorText, setErrorText] = useState('');
    const [createButtonClicked, setCreateButtonClicked] = useState(false);
    const [startButtonClicked, setStartButtonClicked] = useState(false);

    const [selectedModel, setSelectedModel] = useState('');
    const handleModelChange = (event) => {
      setSelectedModel(event.target.value);
      // You can access the selected value here and do what you need with it
      console.log("Selected model:", event.target.value);
    };

    const [selectedStyle, setSelectedStyle] = useState('');
    const handleStyleChange = (event) => {
      setSelectedStyle(event.target.value);
      // You can access the selected value here and do what you need with it
      console.log("Selected style:", event.target.value);
    };

    const callCreateGame = async () => {
        console.log("creating lobby...");
        const response = await fetch('https://create-game-http-meryawfipq-uc.a.run.app', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                gptModel: selectedModel,
                style: selectedStyle
            }),
        }); 
        const data = await response.json();
        setDocId(data.docId);
    };

    const handleButtonClick = () => {
        setCreateButtonClicked(true);
        callCreateGame().catch((error) => {
            console.error(error);
            setErrorText(error.message);
        });
    };

    const startButtonClickedHandler = () => {
        setStartButtonClicked(true);
    }

    const docRef = docId ? doc(db, 'games', docId) : null;

    var [value, loading, error] = useDocument(
        docRef,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    if (!docRef) {
        loading = true;
    }

    const fighterNames = value && value.data().players.map(function (player) {
        return player.fighter_name;
    });

    return (
        <div className="HostEntry">
            { !createButtonClicked &&
                (<div>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                        <InputLabel id="model-label">GPT Model</InputLabel>
                        <Select
                            labelId="model-label"
                            id="model-select"
                            value={selectedModel}
                            label="GPT Model"
                            onChange={handleModelChange}
                        >
                            <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem>
                            <MenuItem value={"gpt-4-turbo-preview"}>gpt-4-turbo-preview</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="style-label">DALL-E Style</InputLabel>
                        <Select
                            labelId="style-label"
                            id="style-select"
                            value={selectedStyle}
                            label="DALL-E Style"
                            onChange={handleStyleChange}
                        >
                            <MenuItem value={"simple sketchbook"}>simple sketchbook</MenuItem>
                            <MenuItem value={"photorealistic"}>photorealistic</MenuItem>
                        </Select>
                    </FormControl>
                    <Button 
                        onClick={handleButtonClick}
                        variant="contained"
                        sx={{ width: '80%' }}>
                        Create Lobby
                    </Button>
                </Box>
                </div>)
            }
            { createButtonClicked && !startButtonClicked &&
                <div>
                    {loading && <span>Creating lobby...</span>}
                    {value && <HostLobby docId={docId} lobbyCode={value.data().login_code} playerNames={fighterNames} startHandler={startButtonClickedHandler} disableButtons={startButtonClicked}/>}
                </div>
            }
            { startButtonClicked &&
                <div>
                    <HostGame docRef={docRef} docValue={value} />
                </div>  
            }
          <ErrorPage errorText={errorText}/>
          <ErrorPage errorText={JSON.stringify(error)}/>
        </div>
      );
};

export default HostEntry;