import './PlayerEntry.css';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import PlayerGame from './PlayerGame';
import ErrorPage from './ErrorPage';

const PlayerEntry = () => {
    const [disableButtons, setDisableButtons] = useState(false);

    const [lobbyCodeFieldValue, setLobbyCodeFieldValue] = useState('');
    const handleLobbyCodeFieldChange = (event) => {
        setLobbyCodeFieldValue(event.target.value);
    }
    
    const [fighterNameFieldValue, setFighterNameFieldValue] = useState('');
    const handleFighterNameFieldChange = (event) => {
        setFighterNameFieldValue(event.target.value);
    }
    
    const [fighterDescriptionFieldValue, setFighterDescriptionFieldValue] = useState('');
    const handleFighterDescriptionFieldChange = (event) => {
        setFighterDescriptionFieldValue(event.target.value);
    }

    const [errorText, setErrorText] = useState('');

    const [docId, setDocId] = useState('');
    const [playerId, setPlayerId] = useState(-1);
    const callJoinGame = async () => {
        setDisableButtons(true);

        try {
            const response = await fetch('https://join-game-http-meryawfipq-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lobbyCode: lobbyCodeFieldValue.toUpperCase(),
                    fighterName: fighterNameFieldValue,
                    fighterDescription: fighterDescriptionFieldValue
                }),
            });
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('Lobby with provided code not found!');
                }
                const data = await response.json();
                throw new Error(`Error: ${response.status} ${response.statusText} ${JSON.stringify(data)}`);
            }
            const data = await response.json();
            setDocId(data.docId);
            setPlayerId(data.playerId);
        } catch (error) {
            console.error("Error:", error);
            setErrorText(error.message);
        }
    }

    const handleButtonClick = () => {
        callJoinGame();
    };

    if (docId === '') {
        return (
            <div className="PlayerEntry">
                <TextField
                    className="TextField"
                    id="lobby-code"
                    label="Lobby Code"
                    variant="outlined"
                    onChange={handleLobbyCodeFieldChange}
                />
                <TextField
                    className="TextField"
                    id="fighter-name"
                    label="Fighter name"
                    variant="outlined"
                    onChange={handleFighterNameFieldChange}
                />
                <TextareaAutosize
                    className="TextareaAutosize"
                    id="fighter-description"
                    label="Fighter description"
                    minRows={3}
                    placeholder="Fighter description"
                    onChange={handleFighterDescriptionFieldChange}
                />
                <Button 
                    disabled={disableButtons}
                    onClick={handleButtonClick}
                    variant="contained">
                    Connect
                </Button>
                <ErrorPage errorText={errorText}/>
            </div>
        );
    } else {
        return <div><PlayerGame docId={docId} playerId={playerId} /></div>;
    }
}

export default PlayerEntry;
