// App.js
import React from 'react';
import { CssBaseline } from '@mui/material';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import PlayerEntry from './PlayerEntry';
import HostEntry from './HostEntry';


const App = () => {
  return (
    <Router>
      <CssBaseline />
      <Routes>
        <Route path="/host" element={<HostEntry />}/>
        <Route path="/" element={<PlayerEntry />}/>
      </Routes>
    </Router>
  );
};

export default App;