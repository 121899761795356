import React from 'react';
import { Box, Button, Typography, Grid, Paper } from '@mui/material';

const VotingScreen = ({ player1, player2, allowVotes, onVotePlayer1, onVotePlayer2 }) => {
  return (
    <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Cast Your Vote!
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h5">{player1.fighter_name}</Typography>
            <Typography variant="subtitle1">Votes: {player1.votes}</Typography>
            { allowVotes && 
              <Button variant="contained" color="primary" onClick={onVotePlayer1}>
                Vote for {player1.name}
              </Button>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h5">{player2.fighter_name}</Typography>
            <Typography variant="subtitle1">Votes: {player2.votes}</Typography>
            { allowVotes && 
              <Button variant="contained" color="primary" onClick={onVotePlayer2}>
                Vote for {player2.name}
              </Button>
            }
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VotingScreen;