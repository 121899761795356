import React from 'react';
import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import './HostLobby.css';
import Button from '@mui/material/Button';

const HostLobby = ({ docId, lobbyCode, playerNames, startHandler, disableButtons }) => {
  return (
    <div className="lobby-container">
      <Typography variant="h4" component="h2" gutterBottom className="lobby-code">
        Lobby Code: {lobbyCode}
      </Typography>
      { playerNames.length > 0 && 
        <div>
            <Paper elevation={3} className="player-list-container">
                <List>
                {playerNames.slice(0, 8).map((name, index) => (
                    <ListItem key={index}>
                    <ListItemText primary={name} />
                    </ListItem>
                ))}
                </List>
            </Paper>
            <Button 
                disabled={disableButtons}
                onClick={startHandler}
                variant="contained">
                Start
            </Button>
        </div>
    }
    </div>
  );
};

export default HostLobby;