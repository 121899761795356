import { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const AudioPlayerComponent = ({ filePath, doneCallback }) => {
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    // Ensure there's no ongoing audio when the component re-renders or filePath changes
    if (audio) {
      audio.pause();
    }

    console.log("fetching audio at %s", filePath);

    const storage = getStorage();
    const audioRef = ref(storage, filePath);

    getDownloadURL(audioRef)
      .then((url) => {
        const newAudio = new Audio(url);
        setAudio(newAudio);
        newAudio.play().catch(error => console.error("Error playing audio:", error));

        // Event listener to perform some action after audio ends
        newAudio.addEventListener('ended', doneCallback);
      })
      .catch((error) => {
        console.error("Error getting audio URL: ", error);
      });

    // Cleanup function to pause audio when component unmounts
    return () => {
      if (audio) {
        audio.pause();
      }
    };
    // eslint-disable-next-line
  }, [filePath]);

  return null; // No visual component
};

export default AudioPlayerComponent;