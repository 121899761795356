import { useState, useEffect } from "react";
import BracketScreen from "./BracketScreen";
import MatchScreen from "./MatchScreen";
import ErrorPage from './ErrorPage';
import { Box, Typography } from '@mui/material';

const HostGame = ({docRef, docValue}) => {
    const [errorText, setErrorText] = useState('');
    const [activeMatchIdx, setActiveMatchIdx] = useState(-1);

    const [startTimer, setStartTimer] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [timerLength, setTimerLength] = useState(1000);
    const [timerDone, setTimerDone] = useState(false);

    useEffect(() => {
        let timerId;
    
        if (startTimer) {
            setTimerDone(false);
          // Start the countdown
          timerId = setInterval(() => {
            setTimerLength((prevCountdown) => {
              if (prevCountdown <= 1) {
                clearInterval(timerId); // Stop the timer when countdown reaches 0
                setTimerDone(true);
                setStartTimer(false);
                return 0;
              }
              return prevCountdown - 1;
            });
          }, 1000); // Decrease countdown every second
        }
    
        return () => {
          if (timerId) {
            clearInterval(timerId); // Clear the interval on component unmount or when startTimer changes to ensure no memory leaks
          }
        };
      }, [startTimer]); // This effect depends on startTimer

    // States: SIMULATING, PRE_MATCH, ACTIVE_MATCH, POST_ROUND, END
    const [hostState, setHostState] = useState('SIMULATING');

    const callRunMatchRound = async () => {
        try {
            const response = await fetch('https://run-match-round-http-meryawfipq-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    docId: docRef.id,
                }),
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(`Error: ${response.status} ${response.statusText} ${JSON.stringify(data)}`);
            }
            setHostState('PRE_MATCH');
        } catch (error) {
            console.error("Error:", error);
            setErrorText(error.message);
        }
    }

    useEffect(()=> {
        callRunMatchRound();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (!docValue) {
            return;
        }
        const matches = docValue.data().matches;

        if (hostState === 'PRE_MATCH') {
            const matchIdx = matches.findIndex(match => match.winner === -1);
            if (matchIdx === -1) {
                if (matches.length === 1) {
                    setHostState('END');
                }
                else {
                    setHostState('POST_ROUND')
                    setTimerLength(5);
                    setStartTimer(true);
                }
            } else {
                setActiveMatchIdx(matchIdx);
                setTimerLength(5);
                setStartTimer(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostState]);

    useEffect(() => {
        if (timerDone) {
            console.log("timer done");
            if (hostState === 'PRE_MATCH') {
                setHostState('ACTIVE_MATCH');
            } else if (hostState === 'POST_ROUND') {
                setActiveMatchIdx(-1);
                callRunMatchRound();
                setHostState('SIMULATING');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerDone]);

    const matchDoneCallback = () => {
        setHostState('PRE_MATCH');
    };

    if (hostState === 'END') {
        const winning_player_id = docValue.data().matches[0].winner
        const winning_player = docValue.data().players[winning_player_id];

        return (
            <div>
                <Box sx={{ height: '100vh', width: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
                    <img
                        src={winning_player.winner_image}
                        alt="Failed to load"
                        style={{ width: 'auto', maxHeight: '60vh' }} />
                    <Typography variant="h4" sx={{ textAlign: 'center', maxWidth: '8vh' }}>
                        {winning_player.fighter_name} wins!
                    </Typography>
                </Box>
            </div>
        );
    }
    else if (hostState === 'PRE_MATCH' || hostState === 'SIMULATING' || hostState === 'POST_ROUND') {
        return (
            <div>
                <BracketScreen docRef={docRef} matchesReady={hostState !== 'SIMULATING'} activeMatchIdx={hostState === 'POST_ROUND' ? -1 : activeMatchIdx} roundEnd={hostState === 'POST_ROUND'}/>
                <ErrorPage errorText={errorText}/>
            </div>
        );
    } else if (hostState === 'ACTIVE_MATCH') {
        return (
            <div>
                <MatchScreen docId={docRef.id} docValue={docValue} activeMatchIndex={activeMatchIdx} doneCallback={matchDoneCallback} />
                <ErrorPage errorText={errorText}/>
            </div>
        );
    }

}

export default HostGame;