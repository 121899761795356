import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

const LoadingIndicator = ({ text }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
      <CircularProgress />
      <Typography variant="h3">{text}</Typography>
    </Box>
  );
};

export default LoadingIndicator;