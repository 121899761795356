import AudioPlayerComponent from "./AudioPlayerComponent";
import { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import VotingScreen from "./VotingScreen";
import ErrorPage from "./ErrorPage";
import LoadingIndicator from "./LoadingIndicator";
import Timer from "./Timer";

const MatchScreen = ({docId, docValue, activeMatchIndex, doneCallback}) => {
    const [errorText, setErrorText] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false); // State to track if the image has loaded

    const matches = docValue.data().matches;
    const players = docValue.data().players;
    const match = matches[activeMatchIndex];

    const player1 = players[match.player_id_1];
    player1.votes = match.votes_1;
    const player2 = players[match.player_id_2];
    player2.votes = match.votes_2;

    const [phaseIdx, setPhaseIdx] = useState(0);
    const voteAfterPhase = 4;

    const [voting, setVoting] = useState(false);

    const phases = [
        {
            txt: match.initial_action_text_1,
            img: match.initial_action_image_1,
            tts: match.initial_action_tts_1
        },
        {
            txt: match.initial_action_text_2,
            img: match.initial_action_image_2,
            tts: match.initial_action_tts_2
        },
        {
            txt: match.initial_action_resolution_text,
            img: match.initial_action_resolution_image,
            tts: match.initial_action_resolution_tts
        },
        {
            txt: match.final_action_text_1,
            img: match.final_action_image_1,
            tts: match.final_action_tts_1
        },
        {
            txt: match.final_action_text_2,
            img: match.final_action_image_2,
            tts: match.final_action_tts_2
        },
        {
            txt: match.final_action_resolution_text_1,
            img: match.final_action_resolution_image_1,
            tts: match.final_action_resolution_tts_1
        },
        {
            txt: match.final_action_resolution_text_2,
            img: match.final_action_resolution_image_2,
            tts: match.final_action_resolution_tts_2
        },
    ]

    const audioDoneCallback = () => {
        console.log("audio done");
        if (phaseIdx === voteAfterPhase) {
            setVoting(true);
        } else if (phaseIdx >= voteAfterPhase) {
            doneCallback();
        } else {
            if (phases[phaseIdx].img !== phases[phaseIdx+1].img) {
                setImageLoaded(false);
            }
            setPhaseIdx(phaseIdx + 1);
        }
    }

    useEffect(() => {
        const callStartVote = async () => {
            try {
                const response = await fetch('https://start-vote-http-meryawfipq-uc.a.run.app', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        docId: docId,
                        match: activeMatchIndex,
                    }),
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(`Error: ${response.status} ${response.statusText} ${JSON.stringify(data)}`);
                }
            } catch (error) {
                console.error("Error:", error);
                setErrorText(error.message);
            }
        }

        if (voting) {
            callStartVote();
        }
    }, [voting, activeMatchIndex, docId]);

    const votingDoneCallback = async () => {
        const callSetWinner = async () => {
            try {
                const response = await fetch('https://set-winner-http-meryawfipq-uc.a.run.app', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        docId: docId,
                        match: activeMatchIndex,
                    }),
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(`Error: ${response.status} ${response.statusText} ${JSON.stringify(data)}`);
                }
                const data = await response.json();
                return data.winner;
            } catch (error) {
                console.error("Error:", error);
                setErrorText(error.message);
            }
        }

        const winner = await callSetWinner();
        
        if (winner === match.player_id_1) {
            setPhaseIdx(phaseIdx + 1);
        } else {
            setPhaseIdx(phaseIdx + 2);
        }
        setVoting(false);
    };

    if (voting) {
        return (
            <div>
                <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
                    <VotingScreen player1={player1} player2={player2} allowVotes={false} />
                    <Timer duration={20} callback={votingDoneCallback} />
                    <ErrorPage errorText={errorText}/>
                </Box>
            </div>
        );
    }
    return (
        <div>
            {!imageLoaded &&
                <LoadingIndicator />
            }
            <AudioPlayerComponent filePath={phases[phaseIdx].tts} doneCallback={audioDoneCallback} />
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                <img
                    src={phases[phaseIdx].img}
                    alt="Failed to load"
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageLoaded(true)}
                    style={{ width: 'auto', maxHeight: '80vh' }} />
                {imageLoaded &&
                    <Typography variant="h4" sx={{ textAlign: 'center', maxWidth: '80%' }}>
                        {phases[phaseIdx].txt}
                    </Typography>
                }
            </Box>
            <ErrorPage errorText={errorText}/>
        </div>
    )
}

export default MatchScreen;