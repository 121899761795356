import React, { useEffect, useState } from 'react';
import './Timer.css'; // Assuming you'll put your CSS here

const Timer = ({ duration, callback }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [callbackCalled, setCallbackCalled] = useState(false);
  // eslint-disable-next-line
  const [radius, setRadius] = useState(50); // Adjust as needed for the size of your circle
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (!callbackCalled) {
        setCallbackCalled(true);
        callback();
    }
    // eslint-disable-next-line
  }, [timeLeft]);

  const strokeDashoffset = () => circumference - (timeLeft / duration) * circumference;

  return (
    <div className="timer">
      <svg width={radius * 2} height={radius * 2} viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
        <circle
          className="timer-circle-bg"
          cx={radius}
          cy={radius}
          r={radius}
          fill="none"
        />
        <circle
          className="timer-circle"
          cx={radius}
          cy={radius}
          r={radius}
          fill="none"
          stroke="tomato"
          strokeWidth="4"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset()}
        />
        <text x="50%" y="50%" textAnchor="middle" stroke="#51c5cf" dy=".3em">{timeLeft}</text>
      </svg>
    </div>
  );
};

export default Timer;