import './PlayerGame.css';
import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { db } from './firebase';
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ErrorPage from './ErrorPage';
import VotingScreen from './VotingScreen';

const Voting = ({docId, docValue, playerId}) => {
    const [errorText, setErrorText] = useState('');
    const [votingEnabled, setVotingEnabled] = useState(true);

    const match = docValue.matches[docValue.voting];
    const players = docValue.players;

    const player1 = players[match.player_id_1];
    player1.votes = match.votes_1;
    const player2 = players[match.player_id_2];
    player2.votes = match.votes_2;

    const selfVote = playerId === match.player_id_1 || playerId === match.player_id_2;

    const callVote = async (button_idx) => {
        setVotingEnabled(false);
        try {
            const response = await fetch('https://cast-vote-http-meryawfipq-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    docId: docId,
                    match: docValue.voting,
                    vote: button_idx,
                }),
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(`Error: ${response.status} ${response.statusText} ${JSON.stringify(data)}`);
            }
        } catch (error) {
            console.error("Error:", error);
            setErrorText(error.message);
        }
    }

    const voteHandler1 = () => {
        callVote(1);
    }

    const voteHandler2 = () => {
        callVote(2);
    }

    return <div className="vote-container">
        {!selfVote &&
        <VotingScreen player1={player1} player2={player2} allowVotes={votingEnabled} onVotePlayer1={voteHandler1} onVotePlayer2={voteHandler2} />
        }
        {selfVote &&
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                    Not voting because you're in the match!
                </Typography>
            </Box>
        }
        <ErrorPage errorText={errorText}/>
    </div>
}

const PlayerGameWithValidDoc = ({docId, docValue, playerId}) => {
    const nonVoteString = !docValue.started ? "Waiting to start..." : "Waiting to vote...";
    if (docValue.voting < 0) {
        return (
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                    {nonVoteString}
                </Typography>
            </Box>
        );
    } else {
        return <Voting docId={docId} docValue={docValue} playerId={playerId} />;
    }
};

const PlayerGame = ({ docId, playerId }) => {
    const docRef = doc(db, 'games', docId);

    const [value, loading, error] = useDocument(
        docRef,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    return (
        <div>
            {loading && <span>Document: Loading...</span>}
            {value && <PlayerGameWithValidDoc docId={docId} docValue={value.data()} playerId={playerId} />}
            <ErrorPage errorText={JSON.stringify(error)}/>
        </div>
      );
};

export default PlayerGame;
