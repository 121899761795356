import React from 'react';
import './MatrixEffect.css'; // Ensure this is pointing to the correct CSS file path

const MatrixEffect = ({text}) => {
  // Function to generate a random character

  const simText = "Running simulations..."
  return (
    <div className="matrix-effect">
        <div className="simulation-text">
            {Array.from({ length: simText.length }).map((_, index) => (
                <span
                key={index}
                style={{
                  textAlign: `center`,
                  "fontSize": `64px`,
                  animationDelay: `${5}s`,
                  animationDuration: `${2 + Math.random() * 4}s`, // Random duration between 2s and 6s
                }}
              >
                {simText.charAt(index % simText.length)}
              </span>))}
        </div>
        <div className="other-text">
      {Array.from({ length: 800 }).map((_, index) => (
        <span
          key={index}
          style={{
            marginLeft: `${30 + Math.random() * 10}px`,
            marginRight: `${30 + Math.random() * 10}px`,
            animationDelay: `${5}s`,
            animationDuration: `${2 + Math.random() * 4}s`, // Random duration between 2s and 6s
          }}
        >
          {text.charAt(index % text.length)}
        </span>
      ))}
      </div>
    </div>
  );
};

export default MatrixEffect;
