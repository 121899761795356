import { useDocument } from 'react-firebase-hooks/firestore';
import MatchList from './MatchList'
import ErrorPage from './ErrorPage';
import { Container, Typography } from '@mui/material';
import MatrixEffect from './MatrixEffect';

const BracketScreen = ({docRef, matchesReady, activeMatchIdx, roundEnd}) => {
    var [value, loading, error] = useDocument(
        docRef,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    if (value) {
        const players = value.data().players;
        const matches = value.data().matches;

        let textForMatrix = "";
        for (const player of players) {
            textForMatrix += player.fighter_name + ": " + player.fighter_description + " ";
        }

        const matchesForMatchList = matches.map((match) => {
            return {
                player1: match.player_id_1 === -1 ? "Bye" : players[match.player_id_1].fighter_name,
                player2: match.player_id_2 === -1 ? "Bye" : players[match.player_id_2].fighter_name,
                player1Desc: match.player_id_1 === -1 ? "" : players[match.player_id_1].fighter_description,
                player2Desc: match.player_id_2 === -1 ? "" : players[match.player_id_2].fighter_description,
                winner: match.winner === -1 ? undefined : players[match.winner].fighter_name
            };
        });

        return (
            <div>
                <Container maxWidth="60vh" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{zIndex: 2}}>
                        <MatchList matches={matchesForMatchList} activeMatchIndex={activeMatchIdx} />
                    </div>
                    { !matchesReady &&
                        <div>
                            <MatrixEffect text={textForMatrix} />
                        </div>
                    }
                    {
                        roundEnd &&
                        <Typography variant="h3">Round complete! Next round starting soon...</Typography>
                    }
                </Container>
            </div>
        );
    }
    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <ErrorPage errorText={JSON.stringify(error)}/>
    }
}

export default BracketScreen;