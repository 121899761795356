import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper } from '@mui/material';
import './MatchList.css'

const MatchList = ({ matches, activeMatchIndex }) => {
    const [blink, setBlink] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
          setBlink((prev) => !prev);
        }, 500); // Toggle blink state every second
    
        return () => clearInterval(interval);
    }, []);

    // Sample match structure: [{ player1: 'Alice', player2: 'Bob', winner: 'Alice' }]
    const getStatusColor = (player, winner) => {
        if (winner === undefined) {
            return 'grey';
        }
        if (winner === player) {
            return 'green';
        }
        return 'red';
    };

    const playerSectionWidth = '20vh';
    return (
        <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
          {matches.map((match, index) => (
            <Paper key={index} elevation={3} sx={{
              p: 2,
              mb: 2,
              borderColor: index === activeMatchIndex && blink ? 'red' : 'transparent',
              borderWidth: index === activeMatchIndex && blink ? '3px' : '3px',
              borderStyle: 'solid',
              animation: 'none',
              width: '100%', 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: index === activeMatchIndex && blink ? 'rgba(255, 0, 0, 0.1)' : '',
            }}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* Player 1 Name and Description */}
                <Box sx={{ minWidth: playerSectionWidth, maxWidth: playerSectionWidth, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ color: getStatusColor(match.player1, match.winner), wordWrap: 'break-word' }}>
                    {match.player1}
                  </Typography>
                  {index === activeMatchIndex && (
                    <Typography variant="body2">
                      {match.player1Desc}
                    </Typography>
                  )}
                </Box>
    
                {/* "vs" Text */}
                <Typography variant="h6" sx={{ mx: 2, alignSelf: 'center' }}>vs</Typography>
    
                {/* Player 2 Name and Description */}
                <Box sx={{ minWidth: playerSectionWidth, maxWidth: playerSectionWidth, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ color: getStatusColor(match.player2, match.winner), wordWrap: 'break-word' }}>
                    {match.player2}
                  </Typography>
                  {index === activeMatchIndex && (
                    <Typography variant="body2">
                      {match.player2Desc}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      );
};

export default MatchList;